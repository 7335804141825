import { useParams } from 'react-router-dom';

import { useParticipantQuery } from 'features/participants/hooks';
import { ChatInterlocutorData } from 'features/socket/config/types/ChatInterlocutor';
import { USER_TYPES } from 'features/user/config';
import { useUserContext } from 'features/user/context';
import { useCoachQuery } from 'features/user/hooks/useCoachQuery';

const getInterlocutor = (data: ChatInterlocutorData) => {
  if (!data) {
    return null;
  }

  const name = data.name || data.email || data.userName || data.userEmail;
  const email = data.email || data.userEmail;
  const picture = data.picture || data.userPicture;

  return {
    name,
    picture,
    email,
    chatId: data.chatId,
  };
};

export default function useChatInterlocutorQuery() {
  const { email } = useParams();
  const { user } = useUserContext();

  const { participant, isFetching: isParticipantFetching } =
    useParticipantQuery(email, {
      enabled: !!email && user.type !== USER_TYPES.USER,
    });

  const { coach, isFetching: isCoachFetching } = useCoachQuery({
    enabled: user.type === USER_TYPES.USER,
  });

  return {
    chatInterlocutor:
      user.type === USER_TYPES.USER
        ? getInterlocutor(coach)
        : getInterlocutor(participant),
    isFetching: isParticipantFetching || isCoachFetching,
  };
}
