import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { getParticipantName } from 'features/participants/utils';
import { UserInfoWithSubtitle } from 'features/user/components/Info';

import Box from 'shared/components/Box';
import { getPictureUrl } from 'shared/utils';

import styles from './ParticipantsTable.module.css';

const UserInfoCellPropTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      unreadNotes: PropTypes.number.isRequired,
    }),
  }),
  value: PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    picture: PropTypes.string,
  }),
};

export const UserInfoCell = ({ value }) => (
  <Box style={{ position: 'relative' }}>
    <Link
      data-testid="user-info"
      className={styles.link}
      to={`/chat/${value.email}`}
    >
      <UserInfoWithSubtitle
        name={getParticipantName(value)}
        subtitle={value.email}
        pictureUrl={getPictureUrl(value.picture)}
        maxWidth="10vw"
      />
    </Link>
  </Box>
);

UserInfoCell.propTypes = UserInfoCellPropTypes;
