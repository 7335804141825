import { lazy, Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Outlet, Route } from 'react-router-dom';

import NotFoundPage from 'pages/NotFound';
import ParticipantsPage from 'pages/ParticipantsPage';

import { AuthProvider } from 'features/auth/providers/AuthProvider';
import { RootRouterErrorBoundary } from 'features/error/components/ErrorBoundary/Router';
import { ChatWithFeatureFlag } from 'features/featureFlags/components/ChatWithFeatureFlag';
import { SocketProviderWithFeatureFlag } from 'features/featureFlags/context/SocketProvider';
import { MainHeader } from 'features/layout/components/Header';
import Layout from 'features/layout/components/Layout/Layout';
import { ChatInterlocutorProvider } from 'features/socket/context/chatInterlocutor';
import { UserProvider } from 'features/user/context';

import Loader from 'shared/components/Loader/Loader';
import lazyRetry from 'shared/utils/lazyRetry';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
    },
  },
});

const ModalContainer = lazy(() =>
  lazyRetry(() => import('shared/components/Modal/ModalContainer'))
);

const AppProviders = () => (
  <AuthProvider>
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <SocketProviderWithFeatureFlag>
          <Outlet />
        </SocketProviderWithFeatureFlag>
      </UserProvider>
      <Suspense fallback={null}>
        <ModalContainer />
      </Suspense>
    </QueryClientProvider>
  </AuthProvider>
);

const appRoutes = (
  <Route element={<AppProviders />} errorElement={<RootRouterErrorBoundary />}>
    <Route path="/" element={<Layout />}>
      <Route path="/participants" element={<ChatWithFeatureFlag />} />
      <Route
        index
        element={
          <>
            <MainHeader />
            <Suspense fallback={<Loader />}>
              <ParticipantsPage />
            </Suspense>
          </>
        }
      />
      <Route
        path="/chat/:email"
        element={
          <Suspense fallback={<Loader />}>
            <ChatInterlocutorProvider>
              <ChatWithFeatureFlag />
            </ChatInterlocutorProvider>
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <>
            <MainHeader />
            <NotFoundPage />
          </>
        }
      />
    </Route>
  </Route>
);

export default appRoutes;
