import dayjs from 'dayjs';

const WEEK_DAY_COUNT = 7;

const DAY_HOURS = 24;
const HOUR_MINUTES = 60;
const MINUTE_SECONDS = 60;
const SECOND_MILLISECONDS = 1000;
export const DAY_MILLISECONDS =
  DAY_HOURS * HOUR_MINUTES * MINUTE_SECONDS * SECOND_MILLISECONDS;

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const DAYS = ['m', 't', 'w', 't', 'f', 's', 's'];

export const getTimestampFromFormattedDate = (formattedDate) => {
  return dayjs.utc(formattedDate, 'YYYY-MM-DD').startOf('day').valueOf();
};

export const getFormattedDate = (date) => {
  let currentDay = date.getUTCDate();
  let currentMonth = date.getUTCMonth() + 1;

  if (currentDay < 10) {
    currentDay = `0${currentDay}`;
  }

  if (currentMonth < 10) {
    currentMonth = `0${currentMonth}`;
  }

  const currentYear = date.getUTCFullYear();

  return `${currentYear}-${currentMonth}-${currentDay}`;
};

export const getPeriodDate = (start, end) => {
  const startDate = new Date(getTimestampFromFormattedDate(start));
  const endDate = new Date(getTimestampFromFormattedDate(end));
  const startDay = startDate.getUTCDate();
  const endDay = endDate.getUTCDate();
  const startMonth = startDate.getUTCMonth();
  const endMonth = endDate.getUTCMonth();
  const startYear = startDate.getUTCFullYear();
  const endYear = endDate.getUTCFullYear();

  return `${MONTHS[startMonth]} ${startDay}${
    startYear !== endYear ? `, ${startYear}` : ''
  } - ${
    startMonth !== endMonth ? `${MONTHS[endMonth]} ` : ''
  }${endDay}, ${endYear}`;
};

export const getSprintWeeksAsFormattedDates = (startDateStr, endDateStr) => {
  const startDate = dayjs(startDateStr);
  const endDate = dayjs(endDateStr);

  const sprintWeeksDateRange = [];
  let currentDay = startDate;

  do {
    const week = [];

    for (let i = 0; i < WEEK_DAY_COUNT; i++) {
      week.push(currentDay.format('YYYY-MM-DD'));
      currentDay = currentDay.add(1, 'day');
    }

    sprintWeeksDateRange.push(week);
  } while (currentDay.isBefore(endDate));

  return sprintWeeksDateRange;
};

export const getStartDayDateObject = (date = dayjs()) => {
  const formattedDate = date.format('YYYY-MM-DD');

  return dayjs.utc(formattedDate, 'YYYY-MM-DD').toDate();
};

export const sortByDateDesc = (array, dateField) =>
  array.sort((item1, item2) =>
    dayjs(item2[dateField]).isAfter(dayjs(item1[dateField])) ? 1 : -1
  );

export const sortByDateAsc = (array, dateField) =>
  array.sort((item1, item2) =>
    dayjs(item1[dateField]).isAfter(dayjs(item2[dateField])) ? 1 : -1
  );

export const getLocalDate = (date) => {
  return dayjs.utc(date).local();
};

export const getLocalDateString = (date) => {
  return dayjs.utc(date).local().toISOString();
};

export const getIsoStringFromDate = (date) =>
  dayjs.utc(date).format('YYYY-MM-DDTHH:mm:ss');

export const isSameDate = (date1, date2, unit = 'milliseconds') => {
  if (!date1 || !date2) {
    return false;
  }

  return dayjs(date1).isSame(date2, unit);
};

// https://github.com/iamkun/dayjs/issues/1886
//  when updateLocale is called, weekStart is not updated
//  so we need to update it manually if monday is the first day of the week
export const getWeekDays = (key) => {
  const localeDayjs = dayjs.localeData();
  const firstDayOfWeek = localeDayjs.firstDayOfWeek();
  const weekDays =
    firstDayOfWeek === 0
      ? localeDayjs[key]().reduce((acc, day, index) => {
          const newIndex = index === 0 ? 6 : index - 1;
          acc[newIndex] = day;

          return acc;
        }, [])
      : localeDayjs[key]();

  return weekDays;
};

export const getAbbreviatedMonthDate = (date) => {
  const localDayjsDate = getLocalDate(date);

  return dayjs().isSame(date, 'year')
    ? localDayjsDate.format('MMM D')
    : localDayjsDate.format('MMM D, YYYY');
};

export const getTodayTimeAgoOrDate = (date) =>
  getLocalDate(date).isToday()
    ? getLocalDate(date).fromNow()
    : getAbbreviatedMonthDate(date);

const DATE_TIME_FORMAT = 'ddd, D MMM';

export const getRelativeDateFormat = (date) => {
  const currentDayDate = dayjs().format(DATE_TIME_FORMAT);
  const prevDayDate = dayjs().subtract(1, 'day').format(DATE_TIME_FORMAT);
  const formattedDate = dayjs(date).format(DATE_TIME_FORMAT);

  if (formattedDate === currentDayDate) {
    return 'Today';
  }
  if (formattedDate === prevDayDate) {
    return 'Yesterday';
  }

  return formattedDate;
};

export const getDateTimestamp = (date) => (date ? dayjs(date).unix() : 0);

export const getDateDiff = (date1, date2) =>
  getDateTimestamp(date1) - getDateTimestamp(date2);
