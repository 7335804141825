const INVITE_MODE = 'invite';

export const enum USER_TYPES {
  COACH = 'coach',
  USER = 'user',
  ADMIN = 'admin',
}

type UserModesType = {
  readonly [key: string]: string;
  readonly admin: string;
  readonly coach: string;
  readonly dashboard: string;
  readonly invite: string;
};

export const USER_MODES: UserModesType = {
  admin: USER_TYPES.ADMIN,
  coach: USER_TYPES.COACH,
  dashboard: USER_TYPES.USER,
  invite: INVITE_MODE,
};

export const USER_TYPE_REDIRECTS = {
  [USER_TYPES.COACH]: 'coach',
  [USER_TYPES.ADMIN]: 'admin',
  [USER_TYPES.USER]: 'dashboard',
};

export const typeEmojies = { coach: '⛰', user: '🎓', admin: '⭐' };

export const PATH_USER_TYPES = {
  coach: USER_TYPES.COACH,
  admin: USER_TYPES.ADMIN,
  dashboard: USER_TYPES.USER,
};
