export const firstLetterUpperCase = (str) =>
  str
    .split(' ')
    .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
    .join('');

export const camelToKebabCase = (str) => {
  return str
    .split('')
    .map((letter, i) =>
      letter.toUpperCase() === letter
        ? `${i !== 0 ? '-' : ''}${letter.toLowerCase()}`
        : letter
    )
    .join('');
};

export const compareStringsIgnoreCase = (strA, strB) =>
  strA.toLowerCase().localeCompare(strB.toLowerCase());
