import React from 'react';

import LazyFeatureFlagComponent from 'features/featureFlags/components/LazyComponent';
import { CHAT_FEATURE_FLAG } from 'features/featureFlags/config';

const LazySocketProvider = React.lazy(
  () => import('features/socket/provider/SocketProvider')
);

type SocketProviderWithFeatureFlagProps = {
  children: React.ReactNode;
};

export const SocketProviderWithFeatureFlag = ({
  children,
}: SocketProviderWithFeatureFlagProps) => (
  <LazyFeatureFlagComponent
    featureFlag={CHAT_FEATURE_FLAG}
    lazyComponent={LazySocketProvider}
  >
    {children}
  </LazyFeatureFlagComponent>
);
