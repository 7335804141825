import dayjs from 'dayjs';
import 'dayjs/locale/en';
import calendar from 'dayjs/plugin/calendar';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(calendar);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isBetween);
dayjs.extend(localeData);
dayjs.extend(isSameOrBefore);

export const getUserTimezone = () => dayjs.tz.guess();

export const timeRegEx = /^(00|10|11|12|[1-9]):([0-5][0-9])(am|pm)$/i;
