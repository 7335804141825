import PropTypes from 'prop-types';
import { useLayoutEffect, useRef } from 'react';

import { sizeValidation } from 'shared/utils/propsValidators';

import styles from 'shared/components/Spacer/Spacer.module.css';

export const sizes = {
  zr: 0,
  xsm: 'var(--spacing00)', // 2px
  sm: 'var(--spacing01)', // 4px
  md: 'var(--spacing02)', // 8px
  lg: 'var(--spacing04)', // 16px
  xlg: 'var(--spacing08)', // 40px
  xxlg: 'var(--spacing12)', // 80px
};

const SpacerPropTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
  size: sizeValidation(sizes),
};

const Spacer = ({ as: Component = 'div', children, size }) => {
  const element = useRef(null);

  useLayoutEffect(() => {
    const elementRef = element.current;
    if (elementRef && size) {
      const spaces = size
        .split(' ')
        .map((space) => sizes[space])
        .join(' ');
      elementRef.style.setProperty('--spacing', spaces);
      elementRef.style.setProperty(
        '--display',
        Component === 'span' ? 'inline-block' : 'block'
      );
    }
  }, [element, size, Component]);

  return (
    <Component className={styles.spacer} ref={element}>
      {children}
    </Component>
  );
};

Spacer.propTypes = SpacerPropTypes;

export default Spacer;
