import { useQuery } from 'react-query';

import { queryKeys } from 'shared/config';
import { useHTTPRequest } from 'shared/hooks/useHTTPRequest';

const useGetParticipants = () => {
  const { request } = useHTTPRequest();
  const { data, status } = useQuery(queryKeys.participants, () =>
    request(`/api/coach/clients`)
  );

  return { participants: data?.items, status };
};

export default useGetParticipants;
