import PropTypes from 'prop-types';
import { useLayoutEffect, useRef } from 'react';

import {
  commonStyles,
  iconNames,
  iconsConfig,
} from 'shared/components/Icon/iconsConfig';
import { camelToKebabCase } from 'shared/utils';

import './Icon.module.css';

const IconPropTypes = {
  name: PropTypes.oneOf(Object.values(iconNames)).isRequired,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  stroke: PropTypes.string,
};

export const Icon = ({
  name,
  className = '',
  stroke = iconsConfig[name].stroke || 'none',
  ...props
}) => {
  const element = useRef(null);
  const { path, ...iconProps } = iconsConfig[name];
  const svgProps = { ...commonStyles, ...iconProps, ...props };

  useLayoutEffect(() => {
    const elementRef = element.current;

    if (elementRef) {
      elementRef.style.setProperty('--stroke', stroke);
    }
  }, [stroke]);

  return (
    <svg
      {...svgProps}
      className={className}
      data-testid={camelToKebabCase(name)}
      xmlns="http://www.w3.org/2000/svg"
      ref={element}
    >
      {Array.isArray(path) ? (
        path.map((config, index) => <path key={index} {...config} />)
      ) : (
        <path d={path} />
      )}
    </svg>
  );
};

Icon.propTypes = IconPropTypes;
