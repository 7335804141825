import { useRouteError } from 'react-router-dom';

import { logError } from 'features/error/utils/logError';

import { GeneralError } from 'shared/components/Error/GeneralError';

const RootRouterErrorBoundary = () => {
  const error = useRouteError();
  logError(error);

  return <GeneralError />;
};

export default RootRouterErrorBoundary;
