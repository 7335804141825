import PropTypes from 'prop-types';
import { useLayoutEffect, useRef } from 'react';

import classNames from 'classnames/bind';

import { VARIANTS } from 'shared/components/Button/config';
import { btnVariantIconColors } from 'shared/utils/config';

import styles from 'shared/components/Button/Button.module.css';

const cx = classNames.bind(styles);

export const ButtonPropTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  role: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  dataTestid: PropTypes.string,
  form: PropTypes.string,
};

const Button = ({
  ariaLabel,
  children,
  className = '',
  disabled = false,
  onClick = () => {},
  onMouseEnter = () => {},
  role = 'button',
  type = 'button',
  value = 'default',
  variant = '',
  dataTestid = '',
  form,
}) => {
  const element = useRef(null);
  const btnClassName = cx('btn', 'btn--button', className, {
    [`btn--${variant}`]: variant,
  });

  useLayoutEffect(() => {
    const elementRef = element.current;
    if (elementRef) {
      elementRef.style.setProperty(
        '--currentColor',
        btnVariantIconColors[variant] || 'var(--black)'
      );
    }
  }, [element, variant]);

  return (
    <button
      aria-label={ariaLabel}
      className={btnClassName}
      disabled={disabled}
      ref={element}
      type={type}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      value={value}
      data-testid={dataTestid}
      role={role}
      form={form}
    >
      {children}
    </button>
  );
};

Button.propTypes = ButtonPropTypes;

export default Button;
