import PropTypes from 'prop-types';
import { useLayoutEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames/bind';

import { btnVariantIconColors } from 'shared/utils/config';

import styles from 'shared/components/Button/Button.module.css';

const cx = classNames.bind(styles);
const ButtonLinkPropTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(Object.keys(btnVariantIconColors)),
  to: PropTypes.string.isRequired,
};
const ButtonLink = ({ children, variant, to }) => {
  const element = useRef(null);

  useLayoutEffect(() => {
    const elementRef = element.current;
    if (elementRef) {
      elementRef.style.setProperty(
        '--currentColor',
        btnVariantIconColors[variant] || 'var(--black)'
      );
    }
  }, [element, variant]);

  return (
    <Link
      className={cx('btn', 'btn--link', `btn--${variant}`)}
      ref={element}
      to={to}
    >
      {children}
    </Link>
  );
};

ButtonLink.propTypes = ButtonLinkPropTypes;

export default ButtonLink;
