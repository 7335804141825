import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import classNames from 'classnames/bind';

import { useUserContext } from 'features/user/context';

import Box from 'shared/components/Box';
import Loader from 'shared/components/Loader';

import styles from 'features/layout/components/Layout/Layout.module.css';

const cx = classNames.bind(styles);

const Layout = () => {
  const { isLoading: isUserLoading } = useUserContext();
  const {
    isLoading: isAuthLoading,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (!isAuthLoading && !isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: `${pathname}${search}`,
        },
      });
    }
  }, [loginWithRedirect, isAuthLoading, isAuthenticated, pathname, search]);

  if (isUserLoading) {
    return (
      <Box style={{ width: '100%', height: '100vh' }}>
        <Loader />
      </Box>
    );
  }

  return (
    <div className={styles.layout}>
      <div className={cx('main-wrapper')}>
        <main className={cx('main-content', 'main-content--coach')}>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;
