import PropTypes from 'prop-types';

import styles from 'App.module.css';

export const toastConfig = {
  className: styles.toast,
  bodyClassName: styles['toast-body'],
  position: 'bottom-center',
  autoClose: 3000,
  hideProgressBar: true,
  newestOnTop: true,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: 'dark',
};

export const toastErrorConfig = { ...toastConfig, icon: false };

export const IconPropTypes = {
  className: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export const queryKeys = Object.freeze({
  mySprints: 'my-sprints',
  participants: 'participants',
  participant: 'participant',
  user: 'user',
  challenges: 'challenges',
  connections: 'connections',
  dimensions: 'dimensions',
  sharedSprints: 'shared-sprints',
  notifications: 'notifications',
  notes: 'notes',
  noteTemplates: 'note-templates',
  challengeNotes: 'challenge-notes',
  users: 'users',
  coaches: 'coaches',
  sessionRecap: 'session-recap',
  attachment: 'attachment',
  chatMessages: 'chats-messages',
});

// TODO: move to features/sprint/config
export const getSharedSprintsQueryKey = (email) => `shared-sprints-${email}`;

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ERRORS = Object.freeze({ ACCESS_DENIED: 'access_denied' });

export const PICTURE_STATUSES = {
  UPLOADING: 'uploading',
  UPLOADED: 'uploaded',
  UPLOAD_FAILED: 'upload_failed',
};

export const HTTP_ERROR_STATUS_CODES = {
  UNAUTHORIZED: '401',
  FORBIDDEN: '403',
  NOT_FOUND: '404',
  INTERNAL_ERROR: '500',
};

export const DEFAULT_RETRY_COUNT = 3;
