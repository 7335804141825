// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = (componentImport, id = '') => {
  const sessionStorageKey = `retry-${id || 'lazy'}-refreshed`;

  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(sessionStorageKey) || 'false'
    );

    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(sessionStorageKey, 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(sessionStorageKey, 'true'); // we are now going to refresh

          return window.location.reload(); // refresh the page
        }

        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

export default lazyRetry;
