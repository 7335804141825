import ParticipantsEmptyState from 'features/participants/components/EmptyState';
import { ParticipantsContent } from 'features/participants/components/ParticipantsContent';
import useGetParticipants from 'features/participants/hooks/useGetParticipants';

import Loader from 'shared/components/Loader';
import Spacer from 'shared/components/Spacer';
import { API_STATUSES } from 'shared/utils/config';

const ParticipantsPage = () => {
  const { participants, status } = useGetParticipants();

  if (status === API_STATUSES.LOADING) {
    return <Loader />;
  }

  return participants.length > 0 ? (
    <Spacer size="lg">
      <Spacer size="md">
        <ParticipantsContent participants={participants} />
      </Spacer>
    </Spacer>
  ) : (
    <ParticipantsEmptyState />
  );
};

export default ParticipantsPage;
