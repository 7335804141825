import snakecase from 'snakecase-keys';

export const snakecasedData = (data) =>
  snakecase(data, {
    deep: true,
    exclude: [/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/],
  });

export const getPropSafely = (object, propName) => object?.[propName];

export const getPictureUrl = (picture) => getPropSafely(picture, 'url');

export const filterFalseyValues = (object) =>
  Object.fromEntries(Object.entries(object).filter(([_, value]) => !!value));
