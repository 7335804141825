import { init } from '@sentry/react';
import { StrictMode } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';

import appRoutes from 'AppRoutes';
import { ENVS } from 'envVariables';

import 'App.module.css';

init({
  dsn: 'https://7a8f980f0fe74f98955e5ebaf37a47c3@o1065040.ingest.sentry.io/4504175220031488',
  environment: process.env.REACT_APP_FRONTEND_ENVIRONMENT || ENVS.LOCAL,
});

const router = createBrowserRouter(createRoutesFromElements(appRoutes));

export const App = () => (
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
);

export default App;
