import PropTypes from 'prop-types';
import { useLayoutEffect, useRef } from 'react';

import styles from 'shared/components/Flex/Flex.module.css';

const FlexItemPropTypes = {
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
  flexGrow: PropTypes.number,
  flexShrink: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  overflow: PropTypes.oneOf(['auto', 'initial', 'scroll', 'hidden']),
  flexBasis: PropTypes.string,
  alignSelf: PropTypes.oneOf([
    'auto',
    'flex-start',
    'flex-end',
    'center',
    'baseline',
    'stretch',
  ]),
};
const FlexItem = ({
  as: Component = 'div',
  children,
  flexGrow = 0,
  flexShrink = 1,
  flexBasis = 'auto',
  width = 'auto',
  height = 'auto',
  overflow = 'initial',
  alignSelf = 'auto',
}) => {
  const element = useRef(null);

  useLayoutEffect(() => {
    const elementRef = element.current;
    if (elementRef) {
      elementRef.style.setProperty('--flex-grow', flexGrow);
      elementRef.style.setProperty('--flex-shrink', flexShrink);
      elementRef.style.setProperty('--width', width);
      elementRef.style.setProperty('--height', height);
      elementRef.style.setProperty('--overflow', overflow);
      elementRef.style.setProperty('--flex-basis', flexBasis);
      elementRef.style.setProperty('--align-self', alignSelf);
    }
  }, [
    element,
    flexGrow,
    flexShrink,
    width,
    height,
    overflow,
    flexBasis,
    alignSelf,
  ]);

  return (
    <Component className={styles['flex-item']} ref={element}>
      {children}
    </Component>
  );
};

FlexItem.propTypes = FlexItemPropTypes;

export default FlexItem;
