import PropTypes from 'prop-types';
import { createContext, useContext, useMemo } from 'react';

import useChatInterlocutorQuery from 'features/socket/hooks/useChatInterlocutorQuery';

const PICTURE_DEFAULT = {
  url: '',
  key: '',
  blurHash: '',
  status: '',
};

const ChatInterlocutorContext = createContext({
  name: '',
  picture: PICTURE_DEFAULT,
  email: '',
  chatId: '',
});

type ChatInterlocutorProviderPropTypes = {
  children: PropTypes.ReactNodeLike;
};

export const ChatInterlocutorProvider = ({
  children,
}: ChatInterlocutorProviderPropTypes) => {
  const { chatInterlocutor, isFetching } = useChatInterlocutorQuery();

  const providerValue = useMemo(
    () => ({
      name: chatInterlocutor?.name || '',
      picture: chatInterlocutor?.picture || PICTURE_DEFAULT,
      email: chatInterlocutor?.email || '',
      chatId: chatInterlocutor?.chatId || '',
    }),
    [chatInterlocutor]
  );

  return (
    <ChatInterlocutorContext.Provider value={providerValue}>
      {!isFetching && chatInterlocutor?.chatId ? children : null}
    </ChatInterlocutorContext.Provider>
  );
};

export const useChatInterlocutorContext = () => {
  const chatInterlocutorContext = useContext(ChatInterlocutorContext);

  if (!chatInterlocutorContext) {
    throw new Error(
      'useChatInterlocutorContext must be used in ChatInterlocutorContext.Provider'
    );
  }

  return chatInterlocutorContext;
};
