import { UserName } from 'features/user/components/Info';
import { userInfo } from 'features/user/config/defaults';
import { UserInfo as UserInfoType } from 'features/user/config/types';

import { Avatar } from 'shared/components/Avatar';
import { Flex } from 'shared/components/Flex';
import Spacer from 'shared/components/Spacer';

export const UserInfo = ({
  pictureUrl,
  name,
  pictureSize = userInfo.pictureSize,
  maxWidth = userInfo.maxWidth,
}: UserInfoType) => (
  <Flex as="span" alignItems="center" justifyContent="flex-start">
    <Avatar name={name} size={pictureSize} src={pictureUrl} />
    <Spacer as="span" size="zr zr zr md">
      <UserName maxWidth={maxWidth}>{name}</UserName>
    </Spacer>
  </Flex>
);
