export const routeNames = {
  DASHBOARD: 'dashboard',
  CONNECTIONS: 'connections',
  COACH: 'coach',
  ADMIN: 'admin',
};

export const days = ['m', 't', 'w', 't', 'f', 's', 's'];
export const DAILY_STATUS_VALUES = {
  SUCCESS: 'success',
  FAIL: 'fail',
  EMPTY: 'empty',
  PASS: 'pass',
};
export const DAILY_STATUSES = [
  DAILY_STATUS_VALUES.SUCCESS,
  DAILY_STATUS_VALUES.FAIL,
  DAILY_STATUS_VALUES.EMPTY,
];

export const btnVariantIconColors = {
  critical: 'var(--white)',
  primary: 'var(--white)',
  secondary: 'var(--black)',
  tertiary: 'var(--black)',
};

export const API_STATUSES = {
  ERROR: 'error',
  LOADING: 'loading',
  SUCCESS: 'success',
};
