import { useAuth0 } from '@auth0/auth0-react';

import { UserInfo } from 'features/user/components/Info';
import { useUserContext } from 'features/user/context';

import Box from 'shared/components/Box';
import Button from 'shared/components/Button';
import { Flex } from 'shared/components/Flex';
import { Icon, iconNames } from 'shared/components/Icon';
import Spacer from 'shared/components/Spacer';
import Typography from 'shared/components/Typography';
import { getPictureUrl } from 'shared/utils';

import styles from 'features/layout/components/Header/Header.module.css';

const MainHeader = () => {
  const { logout } = useAuth0();
  const { user } = useUserContext();

  return (
    <>
      <Box
        style={{
          zIndex: 2,
          boxShadow: '0px 2px 5px var(--section-box-shadow-color)',
        }}
      >
        <header>
          <Spacer size="md lg">
            <Spacer size="zr sm">
              <Typography
                as="div"
                color="gray"
                type="small"
                fontWeight="regular"
              >
                <Flex>
                  {user.email ? (
                    <Spacer size="zr md zr zr">
                      <UserInfo
                        name={user.name || user.email}
                        pictureUrl={getPictureUrl(user.picture)}
                      />
                    </Spacer>
                  ) : null}
                  <Button
                    className={`${styles.btn} ${styles['btn--logout']}`}
                    onClick={() => logout({ returnTo: window.location.origin })}
                    type="button"
                  >
                    <Icon name={iconNames.logout} width={14} height={14} />
                  </Button>
                </Flex>
              </Typography>
            </Spacer>
          </Spacer>
        </header>
      </Box>
    </>
  );
};

export default MainHeader;
