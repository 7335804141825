import { setUser as setSentryUser } from '@sentry/react';
import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import useGetUser from 'features/user/hooks/useGetUser';

import { UserProfile } from 'shared/types/User';

const UserContext = React.createContext({
  user: {} as UserProfile,
  isLoading: false,
});

type UserProviderPropTypes = {
  children: ReactNode;
};

export const UserProvider = ({ children }: UserProviderPropTypes) => {
  const [user, setUser] = useState({} as UserProfile);
  const { userData, isFetching } = useGetUser();

  useEffect(() => {
    if (userData && !isFetching) {
      setUser(userData);
      setSentryUser(userData);
    }
  }, [userData, isFetching]);

  const providerValue = useMemo(
    () => ({
      user,
      setUser,
      isLoading: isFetching,
    }),
    [user, isFetching, setUser]
  );

  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const userContext = useContext(UserContext);

  if (!userContext) {
    throw new Error('useUserContext must be used in UserProvider');
  }

  return userContext;
};
