import { useQuery } from 'react-query';

import { HTTP_ERROR_STATUS_CODES } from 'shared/config';
import { useHTTPRequest } from 'shared/hooks/useHTTPRequest';

export const useCoachQuery = (options = { enabled: true }) => {
  const { request } = useHTTPRequest();
  const { data, status, isFetching } = useQuery(
    'coach',
    () => request('/api/users/me/coach'),
    {
      placeholderData: {
        userEmail: '',
        userName: '',
        userPicture: { url: '' },
        chatId: '',
      },
      enabled: options.enabled ?? true,
      useErrorBoundary: (error) =>
        error?.message !== HTTP_ERROR_STATUS_CODES.NOT_FOUND,
    }
  );

  return { coach: data, status, isFetching };
};
