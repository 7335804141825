import { useQuery } from 'react-query';

import { queryKeys } from 'shared/config';
import { useHTTPRequest } from 'shared/hooks/useHTTPRequest';

const useGetUser = () => {
  const { request } = useHTTPRequest();
  const { data, status, isFetching } = useQuery(
    queryKeys.user,
    () => request('/api/users/me/profile'),
    { initialData: {} }
  );

  return { userData: data, isFetching, status };
};

export default useGetUser;
