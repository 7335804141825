import { CSSProperties, forwardRef } from 'react';

type BoxProps = {
  ariaLabel?: string;
  style?: CSSProperties;
  as?: React.ElementType;
  children?: React.ReactNode;
  role?: string;
  dataTestid?: string;
};

const Box = forwardRef(
  (
    {
      ariaLabel = '',
      as: Component = 'div',
      role = 'generic',
      children,
      dataTestid = '',
      ...otherProps
    }: BoxProps,
    ref
  ) => (
    <Component
      aria-label={ariaLabel}
      role={role}
      ref={ref}
      data-testid={dataTestid}
      {...otherProps}
    >
      {children}
    </Component>
  )
);

export default Box;
