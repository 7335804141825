import { useLayoutEffect, useRef } from 'react';

import classNames from 'classnames/bind';

import styles from 'shared/components/Typography/Typography.module.css';

export const colors = {
  black: 'var(--black)',
  gray: 'var(--gray500)',
  lightGray: 'var(--gray200)',
  lightBlue: 'var(--blue200)',
  green: 'var(--green900)',
  lightGreen: 'var(--green)',
  orange: 'var(--orange900)',
  red: 'var(--red900)',
  lightRed: 'var(--red)',
  yellow: 'var(--yellow900)',
  cyan: 'var(--cyan900)',
  blue: 'var(--blue)',
  purple: 'var(--purple)',
  white: 'var(--white)',
  inherit: 'inherit',
} as const;

const typographyTypes = {
  small: {
    'font-size': '0.75rem',
    'line-height': '1rem',
  },
  meta: {
    'font-size': '0.875rem',
    'line-height': '1.25rem',
  },
  body: {
    'font-size': '1rem',
    'line-height': '1.5rem',
  },
  smallHeading: {
    'font-size': '1rem',
    'line-height': '1.25rem',
  },
  heading: {
    'font-size': '1.25rem',
    'line-height': '1.5rem',
  },
  semiLargeHeading: {
    'font-size': '1.5rem',
    'line-height': '1.75rem',
  },
  largeHeading: {
    'font-size': '1.75rem',
    'line-height': '2.25rem',
  },
  extraLargeHeading: {
    'font-size': '2.75rem',
  },
  inherit: {
    'font-size': 'inherit',
    'line-height': 'inherit',
  },
};

const fontWeights = {
  regular: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
  inherit: 'inherit',
} as const;

const textAlignProps = {
  start: 'start',
  end: 'end',
  left: 'left',
  right: 'right',
  center: 'center',
  justify: 'justify',
  justifyAll: 'justify-all',
  matchParent: 'match-parent',
} as const;

const whiteSpaceProps = {
  initial: 'initial',
  nowrap: 'nowrap',
  breakSpaces: 'break-spaces',
  pre: 'pre',
  preLine: 'pre-line',
  preWrap: 'pre-wrap',
} as const;

const overflowWrapProps = {
  initial: 'initial',
  normal: 'normal',
  anywhere: 'anywhere',
  breakWord: 'break-word',
} as const;

const cx = classNames.bind(styles);

type TypographyProps = {
  as?: React.ElementType;
  title?: string;
  children: React.ReactNode;
  color?: keyof typeof colors;
  fontWeight?: keyof typeof fontWeights;
  textAlign?: keyof typeof textAlignProps;
  type?: keyof typeof typographyTypes;
  whiteSpace?: keyof typeof whiteSpaceProps;
  overflowWrap?: keyof typeof overflowWrapProps;
  cropped?: boolean;
  dataTestid?: string | null;
};

const Typography = ({
  as: Component = 'p',
  title = '',
  children,
  color = colors.inherit,
  fontWeight = fontWeights.inherit,
  textAlign = textAlignProps.start,
  whiteSpace = whiteSpaceProps.initial,
  overflowWrap = overflowWrapProps.initial,
  type = 'inherit',
  cropped = false,
  dataTestid = null,
}: TypographyProps) => {
  const element = useRef<HTMLElement | null>(null);
  const classNames = cx('typography', { 'typography--cropped': cropped });

  useLayoutEffect(() => {
    const elementRef = element.current;
    if (elementRef) {
      const fontProps = typographyTypes[type];
      Object.entries(fontProps).forEach(([prop, value]) =>
        elementRef.style.setProperty(`--${prop}`, value)
      );
      elementRef.style.setProperty('--font-weight', fontWeights[fontWeight]);
      elementRef.style.setProperty('--text-align', textAlignProps[textAlign]);
      elementRef.style.setProperty('--color', colors[color]);
      elementRef.style.setProperty(
        '--white-space',
        whiteSpaceProps[whiteSpace]
      );
      elementRef.style.setProperty(
        '--overflow-wrap',
        overflowWrapProps[overflowWrap]
      );
    }
  }, [element, color, fontWeight, textAlign, type, whiteSpace, overflowWrap]);

  return (
    <Component
      className={classNames}
      ref={element}
      title={title}
      data-testid={dataTestid}
    >
      {children}
    </Component>
  );
};

export default Typography;
