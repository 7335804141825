import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { createSearchParams } from 'react-router-dom';

import replaceKeysWithCamelCase from 'shared/utils/replaceKeysWithCamelCase';

const responseHandlers = {
  'application/json': async (res) => {
    const data = await res.json();

    return replaceKeysWithCamelCase(data);
  },
  'image/jpeg': async (res) => {
    const data = await res.blob();

    return URL.createObjectURL(data);
  },
  'image/png': async (res) => {
    const data = await res.blob();

    return URL.createObjectURL(data);
  },
};

export const request = async (
  getAccessTokenSilently,
  loginWithRedirect,
  url,
  method = 'GET',
  body,
  headers = {},
  isHosApi = true
) => {
  let token = null;

  if (getAccessTokenSilently) {
    try {
      token = await getAccessTokenSilently();
    } catch (e) {
      if (e.error === 'login_required') {
        loginWithRedirect();

        return;
      }

      throw new Error(e.error);
    }
  }
  const fetchOptions = {
    headers: isHosApi
      ? {
          'hOS-API-Version': '8',
          'Content-Type': 'application/json',
          ...headers,
        }
      : headers,
    method,
  };

  if (isHosApi && token) {
    fetchOptions.headers.Authorization = `Bearer ${token}`;
  }

  if (body) {
    fetchOptions.body = body;
  }

  let data;
  let res;

  try {
    const urlWithEncodedParams = getEncodedUrlString(url);
    res = await fetch(urlWithEncodedParams, fetchOptions);
    const contentType = res.headers.get('content-type');
    data = responseHandlers[contentType]
      ? await responseHandlers[contentType](res)
      : null;
  } catch (e) {
    throw new Error(e);
  }

  if (!res.ok) {
    throw new Error(res.status);
  }

  if (!data) {
    return;
  }

  return data;
};

export const useHTTPRequest = ({ isAuthRequired = true } = {}) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const requestFn = useCallback(
    (...args) =>
      request(
        isAuthRequired ? getAccessTokenSilently : null,
        loginWithRedirect,
        ...args
      ),
    [getAccessTokenSilently, loginWithRedirect, isAuthRequired]
  );

  return { request: requestFn };
};

export const getEncodedUrlString = (url) => {
  if (url !== decodeURIComponent(url)) {
    return url;
  }
  const [urlString, searchString] = url.split('?');
  if (!searchString) {
    return urlString;
  }

  const searchParamsArray = searchString
    .split('&')
    .map((searchItem) => [
      searchItem.slice(0, searchItem.indexOf('=')),
      searchItem.slice(searchItem.indexOf('=') + 1),
    ]);
  const searchQuery = createSearchParams(searchParamsArray).toString();

  return `${urlString}?${searchQuery}`;
};
