import { lazy } from 'react';

import LazyFeatureFlagComponent from 'features/featureFlags/components/LazyComponent';
import { CHAT_FEATURE_FLAG } from 'features/featureFlags/config';

const LazyChat = lazy(() => import('features/socket/components/Chat'));

export const ChatWithFeatureFlag = () => (
  <LazyFeatureFlagComponent
    featureFlag={CHAT_FEATURE_FLAG}
    lazyComponent={LazyChat}
  />
);
