import { Flex, FlexItem } from 'shared/components/Flex';
import Spacer from 'shared/components/Spacer';
import Typography from 'shared/components/Typography';

const ParticipantsEmptyState = () => (
  <Flex
    as="article"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    width="100%"
    height="100%"
  >
    <FlexItem width="23rem">
      <Spacer size="zr zr md">
        <header>
          <Spacer size="zr zr md">
            <Typography as="div" type="largeHeading" textAlign="center">
              👋
            </Typography>
          </Spacer>
          <Typography
            as="h1"
            type="body"
            fontWeight="semiBold"
            textAlign="center"
            dataTestid="participants-empty-list"
          >
            There are no participants
          </Typography>
        </header>
      </Spacer>
    </FlexItem>
    <FlexItem width="23rem">
      <Spacer size="zr zr lg">
        <Spacer size="zr zr md">
          <Typography
            as="p"
            color="gray"
            type="meta"
            textAlign="center"
            dataTestid="participants-empty-list-details"
          >
            Your participants will be shown here.
          </Typography>
        </Spacer>
      </Spacer>
    </FlexItem>
  </Flex>
);

export default ParticipantsEmptyState;
