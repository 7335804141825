import { useMemo } from 'react';
import { useSortBy, useTable } from 'react-table';

import { UserInfoCell } from 'features/participants/components/Table/ParticipantsTableCells';
import { sortTableByUserName } from 'features/participants/utils';

const defaultSorting = [
  {
    id: 'userInfo',
    desc: false,
  },
];

const mapParticipantData = (participant) => {
  const { userEmail, userName, userPicture } = participant;

  return {
    userInfo: {
      email: userEmail,
      name: userName,
      picture: userPicture,
    },
  };
};

const defaultColumns = [
  {
    Header: 'Name',
    accessor: 'userInfo',
    Cell: UserInfoCell,
    sortType: sortTableByUserName,
  },
];

export const useParticipantsTable = (participants) => {
  const data = useMemo(
    () => participants.map(mapParticipantData),
    [participants]
  );

  return useTable(
    {
      disableSortRemove: true,
      columns: defaultColumns,
      data,
      initialState: { sortBy: defaultSorting },
      autoResetSortBy: false,
    },
    useSortBy
  );
};
