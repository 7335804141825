import PropTypes from 'prop-types';

import Button, { ButtonPropTypes } from 'shared/components/Button/Button';
import { Icon, iconNames, iconsConfig } from 'shared/components/Icon';

const { children, ...buttonProps } = ButtonPropTypes;

const ButtonIconPropTypes = {
  ...buttonProps,
  icon: PropTypes.oneOf(Object.keys(iconNames)).isRequired,
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number,
  dataTestid: PropTypes.string,
};

export const ButtonIcon = ({
  icon,
  iconWidth = iconsConfig[icon].width,
  iconHeight = iconsConfig[icon].height,
  dataTestid,
  ...props
}) => {
  return (
    <Button {...props} variant="symbol" dataTestid={dataTestid}>
      <Icon name={icon} height={iconHeight} width={iconWidth} />
    </Button>
  );
};

ButtonIcon.propTypes = ButtonIconPropTypes;
