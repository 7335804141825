export const SIZES = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XLG: 'xlg',
};

export const AVATAR_DIMENSIONS = {
  [SIZES.SM]: 24,
  [SIZES.MD]: 32,
  [SIZES.LG]: 40,
  [SIZES.XLG]: 64,
};
