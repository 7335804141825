import PropTypes from 'prop-types';
import { useLayoutEffect, useRef } from 'react';

import styles from 'shared/components/Flex/Flex.module.css';

const FlexPropTypes = {
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  flexDirection: PropTypes.string,
  flexWrap: PropTypes.oneOf([
    'wrap',
    'nowrap',
    'wrap-reverse',
    'inherit',
    'initial',
    'unset',
  ]),
  gap: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  dataTestid: PropTypes.string,
};

const Flex = ({
  as: Component = 'div',
  children,
  justifyContent = 'space-between',
  alignItems = 'center',
  flexDirection = 'row',
  flexWrap = 'nowrap',
  //TODO: width and height should be removed in favour of using Box component
  width = 'auto',
  height = 'auto',
  gap = 'normal',
  dataTestid = null,
}) => {
  const element = useRef(null);

  useLayoutEffect(() => {
    const elementRef = element.current;
    if (elementRef) {
      elementRef.style.setProperty('--justify-content', justifyContent);
      elementRef.style.setProperty('--align-items', alignItems);
      elementRef.style.setProperty('--flex-direction', flexDirection);
      elementRef.style.setProperty('--flex-wrap', flexWrap);
      elementRef.style.setProperty('--width', width);
      elementRef.style.setProperty('--height', height);
      elementRef.style.setProperty('--gap', gap);
    }
  }, [
    element,
    justifyContent,
    alignItems,
    flexDirection,
    width,
    height,
    gap,
    flexWrap,
  ]);

  return (
    <Component className={styles.flex} ref={element} data-testid={dataTestid}>
      {children}
    </Component>
  );
};

Flex.propTypes = FlexPropTypes;

export default Flex;
