import { compareStringsIgnoreCase } from 'shared/utils';

export const getParticipantName = (userInfo) => userInfo.name || userInfo.email;

export const sortTableByUserName = (rowA, rowB) => {
  const rowATitle = getParticipantName(rowA.original.userInfo);
  const rowBTitle = getParticipantName(rowB.original.userInfo);

  return compareStringsIgnoreCase(rowATitle, rowBTitle);
};
