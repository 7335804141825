import React, { Suspense } from 'react';

import { useFeatureFlag } from 'features/featureFlags/hooks/useFeatureFlag';

import Loader from 'shared/components/Loader/Loader';

type LazyFeatureFlagComponentProps = {
  featureFlag: string;
  lazyComponent: React.LazyExoticComponent<React.ComponentType<any>>;
  children?: React.ReactNode;
};

const LazyFeatureFlagComponent = ({
  featureFlag,
  lazyComponent: LazyComponent,
  children,
  ...props
}: LazyFeatureFlagComponentProps) => {
  const isFeatureEnabled = useFeatureFlag(featureFlag);

  return (
    <>
      {isFeatureEnabled ? (
        <Suspense fallback={<Loader />}>
          <LazyComponent {...props}>{children}</LazyComponent>
        </Suspense>
      ) : (
        children || null
      )}
    </>
  );
};

export default LazyFeatureFlagComponent;
