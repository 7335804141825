import { useQuery } from 'react-query';

import {
  DEFAULT_RETRY_COUNT,
  HTTP_ERROR_STATUS_CODES,
  queryKeys,
} from 'shared/config';
import { useHTTPRequest } from 'shared/hooks/useHTTPRequest';

export const useParticipantQuery = (email, options = {}) => {
  const { request } = useHTTPRequest();
  const { data, status, isFetching, error } = useQuery(
    `${queryKeys.participant}-${email}`,
    () => request(`/api/coach/clients/${email}`),
    {
      ...options,
      retry: (failureCount, error) =>
        failureCount < DEFAULT_RETRY_COUNT &&
        error.message !== HTTP_ERROR_STATUS_CODES.FORBIDDEN,
    }
  );

  const placeholderData = {
    userEmail: '',
    userName: '',
    userPicture: { url: '' },
    chatId: '',
  };

  return {
    participant: data || placeholderData,
    status,
    isFetching,
    error,
  };
};
